import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const Container = styled.div`
  position: relative;
  max-width: 100vw;
  height: auto;
  background: #e4e4e4;
  width: 100%;
  padding: 0 calc((100vw - 1200px) / 2);
`;

export default function About(props) {
  const data = useStaticQuery(graphql`
    query GetAboutImage {
      contentfulAsset(contentful_id: { eq: "1Wmme0rmIkrflXTAbmzJgx" }) {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  `);
  const pic = getImage(data.contentfulAsset.gatsbyImageData);
  return (
    <Container>
      <AboutDiv>
        <Text>
          <Title>
            We proudly deliver healthcare to the Willoughby and Langley
            community.
          </Title>
          <Caption>
            Whether you want to return to sport, recover from a work injury, or
            walk along the lake with your grandkids, we can help you return to
            the activities that are most important to you.
          </Caption>
          <Caption>
            Together, we’ll help you get through every step of your journey to
            recovery.
          </Caption>
          <Team to="/practitioners/">
            <Button>Meet Our Team</Button>
          </Team>
        </Text>
        <Image image={pic} alt={``} />
      </AboutDiv>
    </Container>
  );
}

const Image = styled(GatsbyImage)`
  flex: 0 0 47.5%;
  margin-left: 5%;
  height: auto;
  border: 10px white solid;
`;
const AboutDiv = styled.div`
  display: flex;
  padding: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Text = styled.div`
  color: #275f27;
  display: flex;
  flex: 0 0 47.5%;
  flex-direction: column;
  font-family: 'Nunito';
`;
const Title = styled.span`
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
`;
const Caption = styled.span`
  margin-top: 20px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`;

const Button = styled.button`
  height: 100%;
  width: 100%;
  font-size: 22px;
  font-variant: all-small-caps;
  color: white;
  background: #275f27;
  white-space: nowrap;
  padding: 5px 15px 8px;
  border: 1px solid black;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 18;
  }

  :hover {
    background: none;
    color: #275f27;
    border: 2px solid #275f27;
  }
`;
const Team = styled(Link)`
  width: 220px;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin: 25px 0;
`;
