import React from 'react';
import styled from '@emotion/styled';
import Video from './video';
import Mountain from './mountain';

const VideoBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 450px;
  top: -55px;
  z-index: 0;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 55px;
    margin-bottom: 110px;
  }
`;

const Hero = styled.header`
  margin-top: 35px;
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #206741;
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 10px;
  }
`;

const Title = styled.span`
  width: 90%;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
`;

const Text = styled.span`
  width: 90%;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #406551;
`;

const HeaderVideo = (props) => {
  return (
    <VideoBox>
      <Video src="/hike.mp4" />
      <Hero>
        <Title>HOMEOSTASIS</Title>
        <Text>
          The ability to maintain balance despite changes in the environment
          around you.
        </Text>
      </Hero>
      <Mountain />
    </VideoBox>
  );
};

export default HeaderVideo;
