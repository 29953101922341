import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Dropdown } from '../../components/layout/dropdown';
import Container from '../layout/container';

const ServiceGrid = () => (
  <Div>
    <Dropdown type={'text'} openText={'Physiotherapy'}>
      <Text>
        Our Physiotherapists are leaders, educators, and innovators in the
        physiotherapy community, and you can expect the highest standard of care
        that Langley can offer.
      </Text>
      <Details to="/physiotherapy/">View More Details</Details>
    </Dropdown>
    <Dropdown type={'text'} openText={'Chiropractic'}>
      <Text>
        Our chiropractors are extensively educated in the prevention,
        assessment, diagnosis and management of musculoskeletal (MSK) conditions
        and associated neurological issues.
      </Text>
      <Details to="/chiropractic/">View More Details</Details>
    </Dropdown>
    <Dropdown type={'text'} openText={'Massage Therapy'}>
      <Text>
        Benefits from massage therapy extend further than primarily stress
        reduction and relaxation.
      </Text>
      <Details to="/massage-therapy/">View More Details</Details>
    </Dropdown>
    <Dropdown type={'text'} openText={'Occupational Therapy'}>
      <Text>
        Occupational Therapy (OT) helps people who are experiencing difficulty
        in day-to-day activities due to injury, illness, or disability.
        Occupational therapists at Stasis Rehab follow a patient-centred
        practice to help you return to everyday activity and roles.
      </Text>
      <Details to="/occupational-therapy/">View More Details</Details>
    </Dropdown>
    <Dropdown type={'text'} openText={'Clinical Counselling'}>
      <Text>
        The purpose of counselling is to support individuals on their journey
        through life and reveal new opportunities for growth. The benefits of
        counselling are numerous and often include enhanced self awareness,
        improved mood, improved relationships, stress management, and lifestyle
        change.
      </Text>
      <Details to="/counselling/">View More Details</Details>
    </Dropdown>
    <Dropdown type={'textSecondary'} openText={'More Services'}>
      <Dropdown type={'text'} openText={'Acupuncture'}>
        <Text>
          Acupuncture is a safe and effective treatment for a variety of
          illnesses. It involves the placement of extremely fine, sterile
          needles into specific acupoints along the channels of the body. It
          stimulates the release of the body’s own natural pain killers.
        </Text>
        <Details to="/acupuncture/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Kinesiology'}>
        <Text>
          Active rehabilitation is a customized exercise program designed to
          help you recover as quick as possible from a motor vehicle accident.
          This type of therapy is suitable for people still experiencing ongoing
          physical issues from a car accident.
        </Text>
        <Details to="/active-rehab/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Custom Orthotics'}>
        <Text>
          Custom-made orthotics for improved stability, balance, shock
          absorption, posture and alignment can make a significant difference by
          changing the way forces transmit through your foot.
        </Text>
        <Details to="/orthotics/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Intramuscular Stimulation'}>
        <Text>
          IMS is able to treat neuropathic pain by desensitizing affected nerves
          and muscles using acupuncture needles.
        </Text>
        <Details to="/intramuscular-stimulation/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Dizziness/Vestibular'}>
        <Text>
          Vestibular rehabilitation is an exercise-based program, designed by a
          specialty-trained vestibular physical therapist, to improve balance
          and reduce problems related to dizziness.
        </Text>
        <Details to="/dizziness-vestibular/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Concussion Management'}>
        <Text>
          Our Concussion Program is a comprehensive, multidisciplinary program
          which provides assessment, treatment and education for youth and
          adults who have sustained a concussion injury.
        </Text>
        <Details to="/concussion-management/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Shockwave Therapy'}>
        <Text>
          Shockwave Therapy, also known as Extracorporeal Shock Wave Therapy
          (ESWT), is a non-invasive procedure administered by our
          Physiotherapists, where a series of pressurized air wave pulsations
          are applied to the skin in the area where the injury is located.
        </Text>

        <Details to="/shockwave/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Custom Knee Braces'}>
        <Text>
          Knee pain is a common condition that affects many of us as we age
          and/or engage in sport. When it reaches a point where we start
          noticing it on a daily basis, or it starts affecting our normal
          activities, it’s time to come in and see if a custom knee brace is a
          good option for you.
        </Text>
        <Details to="/knee-braces/">View More Details</Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Temporomandibular Joint Disorder'}>
        <Text>
          The temporomandibular joint (TMJ) is the joint that connects the
          mandible (lower jawbone) to the skull. This joint allows the jaw to
          move up and down and side to side, allowing for actions such as
          talking, chewing, and yawning. Dysfunction of the TMJ can cause pain
          and discomfort and is known as temporomandibular joint disorder (TMD).
        </Text>
        <Details to="/temporomandibular-joint-disorder/">
          View More Details
        </Details>
      </Dropdown>
      <Dropdown type={'text'} openText={'Breast/Chest Health'}>
        <Text>
          Our practitioners are well versed in providing care to patients who
          are preparing for and recovering from breast and chest surgery.
        </Text>
        <Details to="/breast-chest-health/">View More Details</Details>
      </Dropdown>
    </Dropdown>
  </Div>
);

const Div = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 100px;
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`;
const Text = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin: 15px 0;
`;
const Details = styled(Link)`
  color: #084f87;
  cursor: pointer;
`;
export default ServiceGrid;
