import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PhilLogo from '../../images/yoga.svg';
// import Mountains from '../../images/mountainrange.svg';
import Container from '../layout/container';
import ServiceGrid from './service-grid';

const Services = () => (
  <Div>
    <Yoga />
    <TitleDiv>
      <Title>OUR SERVICES</Title>
      <Desc>
        Working together throughout your recovery and journey to bring back
        balance to your life.
      </Desc>
    </TitleDiv>
    <ServiceGrid />
  </Div>
);

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 30px;
`;
const Desc = styled.span`
  width: 75%;
  color: #0c6294;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

const Title = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #084f87;
`;
const Yoga = styled(PhilLogo)`
  width: 50px;
  height: 50px;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;

const Div = styled.div`
  position: relative;
  width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Services;
