import * as React from 'react';
import Layout from '../components/layout/layout';
import HeaderVideo from '../components/home/header-video.js';
import Services from '../components/home/services';
import { Seo } from '../components/layout/seo';
import About from '../components/home/about.js';
import Info from '../components/home/info.js';
import Billing from '../components/home/billing.js';
import Reviews from '../components/home/reviews.js';

const IndexPage = () => {
  // console.log('Home rendered');
  // if (typeof window != 'undefined') {
  //   console.log(window.gtag);
  // }
  return (
    <Layout>
      <HeaderVideo />
      <Services />
      <About />
      <Info />
      <Billing />
      {/* <Reviews /> */}
    </Layout>
  );
};

export const Head = (location) => (
  <Seo
    title="Stasis Rehab - Physiotherapy Clinic in Langley"
    pathname={location.pathname}
  >
    <script type="application/ld+json">
      {`
              {
                "@context": "https://schema.org",
                "@type": "MedicalClinic",
                "availableService": "Physiotherapy, Occupational Therapy, Chiropractic, Counselling, Massage Therapy",
                "name": "Stasis Rehab Physiotherapy Clinic",
                "image": "https://www.stasisrehab.ca/static/772215d8258307b03ac6ef43040b9104/62891/stasisabout.webp",
                "@id": "https://www.stasisrehab.ca/langley",
                "url": "https://www.stasisrehab.ca/",
                "telephone": "+1-604-455-7772",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "20728 Willoughby Town Centre Dr Suite 180",
                  "addressLocality": "Langley Township",
                  "addressRegion": "BC",
                  "postalCode": "V2Y 0P3",
                  "addressCountry": "CA"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 49.1452804,
                  "longitude": -122.6469884
                }  
              }
            `}
    </script>
  </Seo>
);
export default IndexPage;
