import React from 'react';
import styled from '@emotion/styled';

const Container = ({ children }) => <Div>{children}</Div>;

export default Container;

const Div = styled.div`
  position: relative;
  width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  height: 100%;
  overflow: visible;
  display: flex;
`;
