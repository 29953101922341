import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

const Container = styled.div`
  position: relative;
  max-width: 100vw;
  height: auto;
  width: 100%;
  padding: 0 calc((100vw - 1200px) / 2);
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  align-content: top;
  margin: 40px 0px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Billing = (props) => {
  return (
    <Container>
      <FlexDiv>
        <FlexBox>
          <StaticImage src="../../images/icbc.png" alt="BlueCross Logo" />
        </FlexBox>
        <Text>
          <Title>ICBC</Title>We excel in managing concussions, neck and back
          pain along with the psychological challenges of dealing with a motor
          vehicle accident.
          <Details to="/billing/">Read More</Details>
        </Text>
      </FlexDiv>
      <FlexDiv>
        <Text>
          <Title>Direct Billing</Title>We direct bill most extended health care
          plans.
          <Details to="/billing/">Read More</Details>
        </Text>
      </FlexDiv>
      <FlexDiv>
        <FlexBox>
          <StaticImage src="../../images/worksafe.png" alt="BlueCross Logo" />
        </FlexBox>
        <Text>
          <Title>WorkSafe BC</Title>Have you been injured in the workplace?
          Treatment cost is billed directly to Worksafe BC upon claim approval.
          <Details to="/billing/">Read More</Details>
        </Text>
      </FlexDiv>
      <FlexDiv>
        <FlexBox>
          <StaticImage src="../../images/bluecross.png" alt="BlueCross Logo" />
        </FlexBox>
        <FlexBox>
          <StaticImage src="../../images/sunlife.png" alt="BlueCross Logo" />
        </FlexBox>

        <FlexBox>
          <StaticImage src="../../images/gsc.png" alt="BlueCross Logo" />
        </FlexBox>

        <FlexBox>
          <StaticImage src="../../images/desjardins.png" alt="BlueCross Logo" />
        </FlexBox>

        <FlexBox>
          <StaticImage src="../../images/manulife.png" alt="BlueCross Logo" />
        </FlexBox>
        <FlexBox>
          <span>And More!</span>
        </FlexBox>
      </FlexDiv>
    </Container>
  );
};

export default Billing;

const FlexDiv = styled.div`
  flex: 0 0 50%;
  height: autos;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  :nth-child(4) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 20px;
    justify-content: center;
    :nth-child(1) {
      order: 3;
    }
    :nth-child(2) {
      order: 1;
    }
    :nth-child(3) {
      order: 4;
      justify-content: center;
    }
    :nth-child(4) {
      order: 2;
    }
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: #084f87;
`;
const Text = styled.div`
  flex: 2 0 65%;
  margin-left: 5%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: top;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`;

const FlexBox = styled.div`
  flex: 0 0 30%;
  padding: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: flex-start;
  }
`;

const Details = styled(Link)`
  color: #084f87;
  cursor: pointer;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`;
