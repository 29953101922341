import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import StarIcon from '../../images/star.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Container = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding: 0 calc((100vw - 1200px) / 2);
  background: rgba(0, 0, 0, 0.78);
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
  }
`;

export default function Reviews(props) {
  const data = useStaticQuery(graphql`
    query {
      googlePlacesPlace {
        name
        rating
        childrenGooglePlacesReview {
          id
          author_name
          text
          rating
          profile_photo_url
        }
        user_ratings_total
      }
      bgImage: contentfulAsset(contentful_id: { eq: "pMRvX15YTgGrxOpPGPYfi" }) {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  `);

  const image = getImage(data.bgImage);

  const reviews = data.googlePlacesPlace.childrenGooglePlacesReview;
  return (
    <Container>
      <Image image={image}>
        {/* <Flex>
          <Title>Local Testimonials</Title>
          <MySwiper
            navigation={true}
            pagination={{
              clickable: true,
            }}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          >
            {reviews.map((review) => {
              return (
                <SwiperSlide
                  key={review.id}
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReviewDiv>
                    <Stars>
                      {[...Array(review.rating)].map((star, i) => {
                        return <Star key={i + review.id} />;
                      })}
                    </Stars>
                    <ReviewText>{review.text}</ReviewText>
                    <Author>- {review.author_name}</Author>
                  </ReviewDiv>
                </SwiperSlide>
              );
            })}
          </MySwiper>
        </Flex> */}
      </Image>
    </Container>
  );
}
const Title = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  color: white;
  margin: 20px;
  margin-top: 40px;
`;
const Image = styled(BgImage)`
  position: relative;
  height: 100%;
  flex: 0 0 100%;
`;
const ReviewDiv = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 10px 10px 50px;
  @media (max-width: 768px) {
    width: 70%;
    margin: 10px 10px 50px;
  }
`;

const MySwiper = styled(Swiper)`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .swiper-button-next,
  .swiper-button-prev {
    color: #0c548d !important;
    fill: #0c548d !important;
    stroke: #0c548d !important;
  }
  .swiper-pagination-bullet {
    background-color: #0c548d;
    display: inline-block;
    border: 2px solid white;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReviewText = styled.p`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const Author = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
`;

const Stars = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: auto;
  margin-bottom: 15px;
  @media (max-width: 768px) {
  }
`;

const Star = styled(StarIcon)`
  width: 19px;
  height: 15px;
  margin: 5px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 768px) {
  }
`;
